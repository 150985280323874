<template>
  <b-modal ref="confirmModal" size="lg" hide-footer title="Hantera ansökan">

    <b-form class="mt-8" @submit.prevent="saveGrant">


      <b-form-group id="input-group-todo_id" label="ID" label-for="input-todo_id">
          <b-form-input
              id="input-todo_id"
              v-model="local_grant.todo_id"
              type="text"
              disabled
          ></b-form-input>
      </b-form-group>



      <b-form-group id="input-group-1" label="Namn på ansökan" label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="local_grant.name"
              type="text"
              placeholder="Namn på ansökan"
              disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group id="descr-group" label="Text" label-for="descr">
          <b-form-textarea
          v-model="local_grant.descr"
          id="input-prop-descr"
          size="sm"
          placeholder="Beskrivande text gällande er ansökan..."
          rows="8"
          disabled
          >
          </b-form-textarea>
      </b-form-group>

      <b-form-group id="input-group-num_participants" label="Antal deltagare (Om evenemang)" label-for="input-num_participants">
        <b-form-input
            id="input-num_participants"
            v-model="local_grant.num_participants"
            type="text"
        ></b-form-input>
      </b-form-group>

      <b-card title="Budget" class="mb-8">
        <GrantBudgetTable
          title="Utgifter"
          :isExpense="true"
          :items="local_grant.budget"
          :showNewButton="false"
          :isDisabled="true"
        />
        <GrantBudgetTable
          title="Intäkter"
          :isExpense="false"
          :items="local_grant.income"
          :showNewButton="false"
          :isDisabled="true"
        />
      </b-card>

      <b-form-group id="input-group-2" label="Summa ansökan (SEK)" label-for="input-2">
          <b-form-input
              id="input-2"
              v-model="local_grant.sum_application"
              type="number"
              placeholder="0"
              disabled
          ></b-form-input>
      </b-form-group>

      <div v-if="local_grant.status == 'WAIT' && local_grant.sum_application > amount_left_for_period" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
          <div class="alert-icon"><i class="flaticon-warning"></i></div>
          <div class="alert-text">Summan i ansökan är <strong>{{ formatsek(local_grant.sum_application) }}kr</strong>, totalt utrymme som föreningen kan ansöka om för perioden är <strong>{{ formatsek(total_sum) }}kr</strong>. Föreningen har tidigare beviljats <strong>{{ formatsek(previously_granted_sum) }}kr</strong> inom denna period.</div>
      </div>

      <div v-if="local_grant.status == 'WAIT' && local_grant.sum_application <= amount_left_for_period" class="alert alert-custom alert-notice alert-light-primary fade show" role="alert">
          <div class="alert-icon"><i class="flaticon-warning"></i></div>
          <div class="alert-text">Summan i ansökan är <strong>{{ formatsek(local_grant.sum_application) }}kr</strong>, totalt utrymme som föreningen kan ansöka om för perioden är <strong>{{ formatsek(total_sum) }}kr</strong>. Föreningen har tidigare beviljats <strong>{{ formatsek(previously_granted_sum) }}kr</strong> inom denna period.</div>
      </div>

      <b-form-group id="input-group-from_datetime" :label="$t('GRANT.FROM_DATETIME')" label-for="input-from_datetime">
        <memlist-date-picker
          v-model="local_grant.from_datetime"
          class="mb-8"
          :placeholder="$t('GRANT.STARTTIME')"
        />
      </b-form-group>

      <b-form-group id="input-group-to_datetime" :label="$t('GRANT.TO_DATETIME')" label-for="input-to_datetime">
        <memlist-date-picker
          v-model="local_grant.to_datetime"
          class="mb-8"
          :placeholder="$t('GRANT.ENDTIME')"
        />
      </b-form-group>

      </b-form>



      <b-row  v-if="local_grant.status === 'WAIT'">
        <b-col lg="6">
          <b-card title="Neka ansökan">
            <b-row>
              <b-col>
                <p>Du kommer att neka ansökan för bidraget. Systemet kommer att skicka ett e-postmeddelande till föreningens administratörer med nedanstående kommentar</p>
              </b-col>
            </b-row>
            <b-form-textarea
                    id="input-comment"
                    v-model="comment_reject"
                    type="text"
                    rows="5"
                    class="mb-4"
                  ></b-form-textarea>
            <b-row>
              <b-col>
                <b-button class="" variant="danger" block @click="onRejectGrantClicked">Neka ansökan</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="6">
          <b-card title="Godkänn ansökan">
            <b-row>
              <b-col>
                <p>Du kommer att godkänna ansökan för bidraget. Systemet kommer att skicka ett e-postmeddelande till föreningens administratörer med nedanstående kommentar</p>
              </b-col>
            </b-row>
                  <b-form-textarea
                    id="input-comment"
                    v-model="comment_accept"
                    type="text"
                    rows="5"
                    class="mb-4"
                  ></b-form-textarea>
            <b-row>
              <b-col>
                <b-button class="" variant="success" block @click="onAcceptGrantClicked">Godkänn ansökan</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

  </b-modal>
</template>

<style lang="scss" scoped>
</style>
<script>
import axios from 'axios';
import dayjs from 'dayjs';
import swal from 'sweetalert2';
import GrantBudgetTable from '@/view/pages/ml/grant/GrantBudgetTable.vue';

import { format_sek } from '@/core/services/currency';

export default {
  name: 'grant-accept-reject-popup',
  components: {
    GrantBudgetTable
  },
  data() {
    return {
      local_grant: {},
      comment_accept: null,
      comment_reject: null,
      total_sum: 0, // the grant total the company is allowed per year, grantSettings.amount
      previously_granted_sum: 0, // total of previously granted
      amount_left_for_period: 0, // total left of period
      amount_left_in_application: 0, // how much is left in the application
      amount_total_in_application: 0,
      grantSettings: { amount: 0, total_used: 0, total_pending: 0 },

    };
  },
  props: ['grant'],
  emits: ['onAcceptGrantClicked', 'onRejectGrantClicked'],
  watch: {
    grant: {
      deep: true,
      handler(val) {
        this.local_grant = { ...val }
        this.loadGrantSettings();
      }
    }
  },
  async mounted() {
    if (this.grant) {
      this.local_grant = { ...this.grant }
    }
  },
  methods: {
    formatsek(sum) {
      return format_sek(sum);
    },

    loadGrantSettings() {
      axios
        .get(`/company/settings/grant/${this.local_grant.creator_company_id}/${dayjs().format('YYYY-MM-DD')}`)
        .then(res => {
          this.grantSettings = res.data;

          this.amount_total_in_application = 0;

          for (const b of this.local_grant.budget) {
            this.amount_total_in_application += b.amount;
          }

          this.total_sum = this.grantSettings.amount;
          this.previously_granted_sum = this.grantSettings.total_used - this.local_grant.sum_application;

          if (this.total_sum <= this.grantSettings.total_used) {
            this.amount_left_for_period = 0;
            this.amount_left_in_application = 0;
          }
          else {
            this.amount_left_for_period = this.total_sum - this.grantSettings.total_used;
            this.amount_left_in_application = this.amount_left_for_period;
          }
        })
        .catch(err => {
          console.error(err);
        });
    },

    onRejectGrantClicked() {
      swal.fire('Nekat bidrag', 'Föreningsansökan har nekats', 'error');

      this.$refs['confirmModal'].hide();
      this.$emit('onRejectGrantClicked', this.local_grant, this.comment_reject);
    },

    onAcceptGrantClicked() {
      swal.fire('Godkänt bidrag', 'Föreningsansökan har godkänts', 'success');

      this.$refs['confirmModal'].hide();
      this.$emit('onAcceptGrantClicked', this.local_grant, this.comment_accept);
    },

    show() {
      this.comment_reject = '';
      this.comment_accept = '';
      this.$refs['confirmModal'].show();
    },

  },
  computed: {

  }
};
</script>
