<template>
  <div id="page-application-view">
    <b-card
      title=""
      class="mb-2"
      hide-footer
    >

    <GrantApplicationTable
      @select_todo_clicked="select_todo_clicked"
      @create_todo_clicked="create_todo_clicked"
      @delete_todo_clicked="delete_todo_clicked"
      :grants="grants"
    />

    <GrantApplicationForm
        class="grant-form"
        v-if="editing || creating"
        ref="grantForm"
        @onGrantUpdated="onGrantUpdated"
        @onGrantCreated="onGrantCreated"
        @onGrantPublished="onGrantPublished"
    />

    </b-card>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import GrantApplicationTable from '@/view/pages/ml/grant/GrantApplicationTable.vue';
import GrantApplicationForm from '@/view/pages/ml/grant/GrantApplicationForm.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'grant-application-view',
  components: {
    GrantApplicationTable,
    GrantApplicationForm
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    this.loadCompanyGrants(this.currentCompanyId);
  },
  data() {
    return {
      editing: false,
      creating: false,
      selectedGrant: null,
      grants: [
          {
            todo_id: 1,
            name: 'Ansökan 1',
            status: 'DRAFT',
            sum: 1000,
            from_datetime: '2021-02-16 20:00:00',
            descr: 'Detta är lite beskrivande text'
          }
      ]
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.editing = this.creating = false;
        this.loadCompanyGrants(this.currentCompanyId);
      }
    }
  },
  methods: {
    select_todo_clicked(todo_id) {
      this.selectGrant(todo_id);
    },

    selectGrant(todo_id) {
      this.editing = true;

      const todo = this.grants.find(item => item.todo_id === todo_id);
      this.selectedGrant = todo;

      this.$nextTick(() => {
        this.$refs['grantForm'].setForm(todo);
        this.scrollToClassName('grant-form');
      });

    },

    async onGrantPublished(todo) {
      try {
        const i = this.grants.findIndex(item => item.todo_id === todo.todo_id);

        if (i < 0) {
          console.error('couldnt find todo: ' + i);
          return;
        }

        const res = await axios.post(`/todo/review/${todo.todo_id}`, todo)

        if (res.status === 200) {

          this.grants[i] = res.data;
          this.grants = [...this.grants];

          swal.fire('Ansökan skickad', 'Bidragsansökan har skickats för granskning', 'success');
          this.$refs['grantForm'].setForm(res.data);
          return;
        }

      }
      catch (err) {
        console.error('onGrantPublished error', err);
      }

      swal.fire('Ett fel uppstod', 'Kontakta memlist supporten', 'error');

    },

    onGrantCreated(todo) {
      this.grants.push(todo);
      this.creating = false;
    },

    onGrantUpdated(todo) {
      const i = this.grants.findIndex(item => item.todo_id === todo.todo_id);

      if (i < 0) {
        console.error('couldnt find todo: ' + i);
        return;
      }

      this.grants[i] = todo;
      // Vue3 compatability for Vue.set
      this.grants = [...this.grants];

      this.editing = false;
    },

    delete_todo_clicked(todo_id) {
      axios
        .delete(`/todo/${todo_id}`)
        .then(res => {
          if (res.status === 204) {
            if (this.selectedGrant.todo_id === todo_id) {
              this.editing = false;
            }
            this.grants = this.grants.filter(item => item.todo_id !== todo_id);
            this.toastr('success', this.$t('COMMON.OK'), 'Raderat');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte radera');
        });

    },

    create_todo_clicked() {
      this.creating = true;

      this.$nextTick(() => {
        this.$refs['grantForm'].resetForm();
        this.scrollToClassName('grant-form');
      });

    },

    loadCompanyGrants(company_id) {
      axios
        .get(`/todo/mygrants/${company_id}`)
        .then(res => {
          this.grants = res.data;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista ansökningar');
        });
    },

    scrollToClassName(classname) {

      this.$nextTick(() => {
        const el = this.$el.getElementsByClassName(classname)[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      });
    },

  }
};
</script>
