<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">

    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">{{title}}</span>
      </h3>

      <div class="card-toolbar" v-if="showNewButton">
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm"
          @click.prevent="onCreateNewRowClicked()"
        >
          <i class="menu-icon flaticon2-plus" style="font-size: 1em"></i>{{ isExpense ? 'Skapa ny utgift' : 'Skapa ny intäkt' }}
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
          <thead>
            <tr class="text-left">
              <th v-if="!isExpense">Sökt bidrag</th>
              <th style="min-width: 110px">{{ isExpense ? 'Utgiftstyp' : 'Intäktstyp' }}</th>
              <th style="min-width: 110px">Summa</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in items">
              <GrantBudgetTableRow
                v-bind:key="i"
                :row="item"
                :currentCompanyId="currentCompanyId"
                :isDisabled="isDisabled"
                :isExpense="isExpense"
                @delete_row_clicked="delete_row_clicked"
                @onRowChanged="onRowChanged"
                @toastr="toastr"
              />
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
import axios from 'axios';
import GrantBudgetTableRow from '@/view/pages/ml/grant/GrantBudgetTableRow.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'grant-budget-table',
  props: ['items','showNewButton','isDisabled','title','isExpense'],
  emits: ['createRowClicked', 'delete_row_clicked', 'onRowChanged'],
  mixins: [ toasts ],
  components: {
    GrantBudgetTableRow
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    onCreateNewRowClicked() {
      this.$emit('createRowClicked');
    },
    delete_row_clicked(id) {
      this.$emit('delete_row_clicked', id);
    },
    onRowChanged(row) {
      this.$emit('onRowChanged', row);
    }
  },
  data() {
    return {

    };
  }
};
</script>
