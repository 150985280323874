<template>
    <div>
        <b-row>
            <b-col>
                <b-form-group id="input-default_grant_total" label="Bidragstak för nya föreningar" label-for="input-default_grant_total">
                    <b-form-input
                        id="input-default_grant_total"
                        v-model="default_grant_total"
                        type="text"
                        placeholder="Bidragstak SEK"
                        @change="onSettingsChanged"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div class="table-responsive">
                    <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
                        <thead>
                        <tr class="text-left">
                            <th style="min-width: 110px">Förening</th>
                            <th style="min-width: 110px">Förbrukad summa</th>
                            <th style="min-width: 110px">Bidragstak</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(item, i) in rows">
                            <GrantCompanySettingsTableRow
                            v-bind:key="i"
                            :row="item"
                            :currentCompanyId="currentCompanyId"
                            @onRowChanged="onRowChanged"
                            @toastr="toastr"
                            />
                        </template>
                        </tbody>
                    </table>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios';
import GrantCompanySettingsTableRow from '@/view/pages/ml/grant/GrantCompanySettingsTableRow.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'grant-company-settings-table',
  props: ['rows','system'],
  emits: ['onSettingsChanged', 'onRowChanged'],
  components: {
    GrantCompanySettingsTableRow
  },
  mixins: [ toasts ],
  watch: {
    system(newValue, oldvalue) {
      this.default_grant_total = newValue.default_grant_total;
    }
  },
  mounted() {

    this.default_grant_total = this.system.default_grant_total;
  },
  methods: {
    onSettingsChanged() {
      let num = parseInt(this.default_grant_total+'');

      if (isNaN(num)) {
        num = 0;
      }

      this.default_grant_total = num;

      this.$emit('onSettingsChanged', this.default_grant_total);
    },
    onRowChanged(row) {
      this.$emit('onRowChanged', row);
    }
  },
  data() {
    return {
      default_grant_total: 0
    };
  }
};
</script>
