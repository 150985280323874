<template>
  <tr>
    <td>
      {{ task.name }}
    </td>
    <td>
      {{ task.updated_at }}
    </td>
    <!--<td>
      <b-form-select id="status" v-model="status" :options="statusOptions"></b-form-select>
    </td>-->
    <td class="text-center">
      {{ this.task.comments ? this.task.comments.length : 0 }}
    </td>
    <td class="text-right pr-0">
      <a
        v-if="task.files && task.files.length > 0 && task.type === 'FILE_WIZARD'"
        href="#"
        class="btn btn-icon btn-light btn-sm mx-2"
        @click="show_files_clicked"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <!--begin::Svg Icon-->
          <inline-svg src="/assets/svg/download-solid.svg"></inline-svg>
          <!--end::Svg Icon-->
        </span>
      </a>

      <a
        class="btn btn-icon btn-light btn-sm mx-2"
        @click="gotoGrantWizard"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <!--begin::Svg Icon-->
          <inline-svg src="/assets/svg/file-invoice-dollar-solid.svg"></inline-svg>
          <!--end::Svg Icon-->
        </span>
      </a>


      <a href="#" class="btn btn-icon btn-light btn-sm mx-2" @click="show_comments_clicked">
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <!--begin::Svg Icon-->
          <inline-svg src="/assets/svg/chat.svg"></inline-svg>
          <!--end::Svg Icon-->
        </span>
      </a>
      <a v-if="!preventEditing" href="#" class="btn btn-icon btn-light btn-sm mx-2" @click="select_row_clicked">
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <!--begin::Svg Icon-->
          <inline-svg src="/assets/svg/Write.svg"></inline-svg>
          <!--end::Svg Icon-->
        </span>
      </a>

    </td>
  </tr>
</template>
<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
.hplus {
  cursor: pointer;
}
.hminus {
  cursor: pointer;
}
</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'grant-top-company-child-table-row',
  props: ['type', 'row', 'preventEditing'],
  emits: ['show_files_clicked', 'show_comments_clicked', 'select_row_clicked', 'delete_row_clicked', 'update_task_status'],
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  data() {
    return {
      task: { files: [] },
      /*row: {
        name: 'title',
        file_id: 'file_id',
        id: 'todo_task_id',
        status: 'status',
        updated_at: '2020-01-01',
        num_comments: 0
      },*/
      statusOptions: [
        //{ value: 'DRAFT', text: 'Utkast' },
        //{ value: 'INVALID', text: 'Ej giltig' },
        //{ value: 'WAIT', text: 'Väntar' },
        { value: 'NOT', text: 'Komplettera' },
        { value: 'DONE', text: 'Klar' },
        //{ value: 'CN', text: 'Bekräftat' },
        //{ value: 'DEL', text: 'Raderat' },
        //{ value: 'ACC', text: 'Accepterat' },
        //{ value: 'REJ', text: 'Avslaget' },
        //{ value: 'BAC', text: 'Backlog' },
        //{ value: 'FUT', text: 'Framtida' },
        { value: 'CLOSE', text: 'Avslutat' }
      ],
      statusName: {
        DRAFT: 'Utkast',
        INVALID: 'Ej giltig',
        WAIT: 'Handläggs',
        NOT: 'Komplettera',
        DONE: 'Klar',
        CN: 'Bekräftat',
        DEL: 'Raderat',
        ACC: 'Accepterat',
        REJ: 'Avslaget',
        BAC: 'Backlog',
        FUT: 'Framtida',
        CLOSE: 'Avslutat'
      },
      status: ''
    };
  },
  mounted() {
    this.status = this.row.status;
    this.task = this.row;

  },
  watch: {
    status(newValue, oldValue) {

      if (newValue && oldValue !== '') {
        this.changeStatus(newValue);
      }
    },
    row: {
      handler(val) {
        this.task = val;
      },
      deep: true
    }
  },
  methods: {
    gotoGrantWizard() {
      this.$router.push(`/ml-grant-wizard/${this.row.todo_id}`);
    },

    changeStatus(newStatus) {
      axios
        .put(`/todo_task/${this.row.todo_task_id}`, { status: newStatus })
        .then(res => {
          // this.status = res.data.status;
          this.task = res.data;
          this.$emit('update_task_status', this.row.todo_task_id, newStatus, this.task);
          this.toastr('success', this.$t('COMMON.OK'), 'Status uppdaterat');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera status');
        });
    },

    show_files_clicked() {
      this.$emit('show_files_clicked', this.row.todo_task_id);
    },
    show_comments_clicked() {
      this.$emit('show_comments_clicked', this.row.todo_task_id);
    },
    select_row_clicked() {
      this.$emit('select_row_clicked', this.row.todo_task_id);
    },
    delete_row_clicked() {
      this.$emit('delete_row_clicked', this.row.todo_task_id);
    },

  }
};
</script>
