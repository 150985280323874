<template>
  <div id="page-top-company-view">

    <b-card>
      <v-tabs background-color="white">
        <v-tab>Ansökningar</v-tab>
        <v-tab>{{$t('COMMON.SETTINGS')}}</v-tab>

        <v-tab-item class="py-8">

          <AcceptRejectGrantPopup
            ref="handleGrantModal"
            :grant="selectedGrant"
            @onRejectGrantClicked="onRejectGrantClicked"
            @onAcceptGrantClicked="onAcceptGrantClicked"
          />

          <FilesListModal
            ref="filesListModal"
            :task="selectedTask"
            @deleteFileClicked="deleteTodoTaskFileClicked"
          />

          <b-modal ref="chatBoxModal" size="lg" hide-footer>
            <ChatBox :comments="comments" />

            <b-row>
              <b-col lg="8">
                <b-form-group id="input-group-text" label="Skriv meddelande" label-for="input-text">
                  <b-textarea id="input-text" v-model="comment" class="mb-2"> </b-textarea>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-button
                  type="button"
                  style="margin-top: 26px; height: 55px; width: 120px;"
                  variant="primary"
                  @click="sendCommentClicked"
                  >Skicka</b-button
                >
              </b-col>
            </b-row>
          </b-modal>

          <GrantTopCompanyTable
              :grants="grants"
              @selectGrantClicked="selectGrantClicked"
              @deleteGrantClicked="deleteGrantClicked"
              @onStatusChanged="onGrantStatusChanged"
              @showTodoTaskFilesClicked="showTodoTaskFilesClicked"
              @showTodoTaskCommentsClicked="showTodoTaskCommentsClicked"
              @selectTodoTaskClicked="selectTodoTaskClicked"
              @deleteTodoTaskClicked="deleteTodoTaskClicked"
              @updateTodoTaskStatus="updateTodoTaskStatus"
          />
        </v-tab-item>
        <v-tab-item class="py-8">
          <GrantSettingsView
            @onSettingsUpdated="onSettingsUpdated"
           />
        </v-tab-item>
    </v-tabs>
    </b-card>



  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import GrantTopCompanyTable from '@/view/pages/ml/grant/GrantTopCompanyTable.vue';
import AcceptRejectGrantPopup from '@/view/pages/ml/grant/AcceptRejectGrantPopup.vue';
import GrantSettingsView from '@/view/pages/ml/grant/GrantSettingsView.vue';
import FilesListModal from '@/view/pages/ml/todo/FilesListModal.vue';
import ChatBox from '@/view/components/chatBox.vue';
import Confirm from '@/view/components/Confirm.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'grant-top-company-view',
  components: {
    GrantTopCompanyTable,
    AcceptRejectGrantPopup,
    GrantSettingsView,
    FilesListModal,
    ChatBox,
    Confirm
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'currentUser'])
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      editing: false,
      creating: false,
      selectedGrant: {},
      selectedTask: null,
      selectedTasks: [],
      comments: [],
      comment: '',
      grants: [
          {
            todo_id: 1,
            name: 'Ansökan 1',
            status: 'NOT',
            sum: 1000,
            from_datetime: '2021-02-16 20:00:00',
            descr: 'Detta är lite beskrivande text'
          }
      ]
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {

      }
    }
  },
  methods: {
    onRejectGrantClicked(grant, comment) {
      grant.comment = comment;
      grant.status = 'REJ';

      this.updateGrant(grant);
    },

    onAcceptGrantClicked(grant, comment) {
      grant.comment = comment;
      grant.status = 'ACC';

      this.updateGrant(grant);
    },

    onGrantStatusChanged(todo_id, status) {
      this.updateGrant({ todo_id, status });
    },

    updateGrant(grant) {
      axios
        .put(`/todo/${grant.todo_id}`, grant)
        .then(res => {
          if (res.status === 200) {
            const i = this.grants.findIndex(item => item.todo_id === grant.todo_id);

            const updatedGrant = res.data;

            const oldGrant = this.grants[i];
            oldGrant.status = updatedGrant.status; // copy over to the "oldGrant" because the oldGrant contains extended data (tasks, files)
            oldGrant.name = updatedGrant.name;
            oldGrant.comment = updatedGrant.comment;
            oldGrant.updated_at = updatedGrant.updated_at;

            this.grants[i] = oldGrant;
            // Vue3 compatability for Vue.set
            this.grants = [...this.grants];

            this.toastr('success', this.$t('COMMON.OK'), 'Ansökan uppdaterad');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera ansökan');
        });
    },

    // when deleting a file from a task
    deleteTodoTaskFileClicked(file_id, todo_task_id) {
      this.selectedTask.files = this.selectedTask.files.filter(item => item.file_id !== file_id);

      axios
        .put(`/todo_task/${todo_task_id}`, this.selectedTask)
        .then(res => {
          this.selectedTask = res.data.task;

          this.toastr('success', this.$t('COMMON.OK'), 'Filen togs bort');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte radera fil');
        });
    },

    showTodoTaskFilesClicked(todo_task_id) {
      axios
        .get(`/todo_task/${todo_task_id}`)
        .then(res => {
          this.selectedTask = res.data;
          this.$refs['filesListModal'].show();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta åtgärdsdata');
        });
    },

    getTodoWithId(todo_id) {
      for (var i = 0; i < this.todos.length; ++i) {
        if (this.todos[i].todo_id === todo_id) {
          return this.todos[i];
        }
      }

      return null;
    },

    selectGrantClicked(todo_id) {

      var todo = this.getTodoWithId(todo_id);
      this.selectedTasks = todo.tasks;

      this.selectedTodoId = todo_id;
      this.selectedGrant = this.grants.find(item => item.todo_id === todo_id);


      this.$refs['handleGrantModal'].show();
    },

    deleteGrantClicked(todo_id) {
      this.grants = this.grants.filter(item => item.todo_id !== todo_id);
    },

    sendCommentClicked() {

      axios
        .post(`/todo_task/comment/${this.todo_task_id}`, {
          from: 'top',
          comment: this.comment,
          todo_task_id: this.todo_task_id
        })
        .then(res => {
          this.comment = '';
          this.comments.push(res.data);
          this.loadData();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skicka kommentar');
        });
    },

    showTodoTaskCommentsClicked(todo_task_id) {

      this.todo_task_id = todo_task_id;

      axios
        .get(`/todo_task/${todo_task_id}`)
        .then(res => {
          this.comments = res.data.comments;

          this.$refs['chatBoxModal'].show();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta kommentarer');
        });
    },

    selectTodoTaskClicked(todo_task_id) {

      for (const todo of this.liveTodos) {
        for (const task of todo.tasks) {
          if (task.todo_task_id === todo_task_id) {
            this.liveTodoTask = task;

            this.$nextTick(() => {
              this.$refs['editLiveTask'].init();
            });

            return;
          }
        }
      }
    },
    deleteTodoTaskClicked(todo_task_id) {
      axios
        .delete(`/todo_task/${todo_task_id}`)
        .then(res => {
          if (res.status === 204) {
            this.liveTodos.filter((todo, i) => {
              todo.tasks.filter((task, t) => {
                if (task.todo_task_id === todo_task_id) this.liveTodos[i].tasks.splice(t, 1);
              });
            });
            this.toastr('success', this.$t('COMMON.OK'), 'Åtgärd togs bort');
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ta bort åtgärd');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ta bort åtgärd');
        });

    },
    updateTodoTaskStatus(todo_task_id, status, todo_task) {
      this.loadData();
    },
    todo_updated(todo) {
      this.loadData();
    },
    todoPublished(todo) {
      this.loadData();
    },
    async loadData() {
      axios
        .get(`/todo?type=GRANT&company_id=${this.currentCompanyId}`)
        .then(res => {
          this.todos = res.data;

          this.grants = [];

          for (var i = 0; i < this.todos.length; ++i) {
            var t = this.todos[i];

            if (t.status === 'DRAFT') {
              continue;
            }

            this.grants.push(t);
          }
          this.grants.map(function(item) {
            if (item.company_name == '') item.company_name = this.$t('COMMON.ALL');
          });
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta ärenden');
        });
    },

    create_todo_clicked() {

      const tab_ref = this.selectedTab();
      this.$refs[`${tab_ref}`].show();
    },

    select_todo_clicked(todo_id) {
      const tab_ref = this.selectedTab();
      this.$refs[`${tab_ref}`].selectTodo(todo_id);
    },

    delete_todo_clicked(todo_id) {

      axios
        .delete(`/todo/${todo_id}`)
        .then(res => {
          if (res.status === 204) {
            this.grants = this.todos.filter(item => item.todo_id !== todo_id);

            this.toastr('success', this.$t('COMMON.OK'), 'Ärendet togs bort');
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ta bort ärende');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ta bort ärende');
        });
    },

    scrollToClassName(classname) {

      this.$nextTick(() => {
        const el = this.$el.getElementsByClassName(classname)[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      });

    },

  }
};
</script>
