<template>
  <tr>
    <td>
      {{ row.name }}
    </td>
    <td>
      {{ row.total_used }}
    </td>
    <td>
      <b-form-input
          id="input-amount"
          v-model="amount"
          type="text"
          v-on:change="onSumUpdated"
      ></b-form-input>
    </td>
  </tr>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

export default {
  name: 'grant-company-settings-table-row',
  props: ['row'],
  emits: ['onRowChanged'],
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  data() {
    return {
      amount: 0
    };
  },
  mounted() {
    this.amount = this.row.amount;
  },
  watch: {},
  methods: {
    onSumUpdated(evt) {
      this.amount = parseInt(this.amount+'');
      if (isNaN(this.amount)) {
        this.amount = 0;
      }

      this.$emit('onRowChanged', { company_id: this.row.company_id, amount: this.amount });
    },
    onNameUpdated() {
      this.amount = parseInt(this.amount+'');
      if (isNaN(this.amount)) {
        this.amount = 0;
      }

      this.$emit('onRowChanged', { company_id: this.row.company_id, amount: this.amount });
    }
  }
};
</script>
