<template>
  <tr>
    <td v-if="!isExpense">
      <div class="d-flex align-items-center mb-4">

        <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
          <input type="checkbox" v-model="apply" @click="apply = !apply" />
          <span></span>
        </label>
        <span class="ml-3 cursor-pointer"></span>
      </div>

    </td>
    <td>
      <b-form-input
          id="input-name"
          v-model="name"
          type="text"
          v-on:change="onNameUpdated"
          :disabled="isDisabled"
      ></b-form-input>
    </td>
    <td>
      <b-form-input
          id="input-amount"
          v-model="amount"
          type="text"
          v-on:change="onSumUpdated"
          :disabled="isDisabled"
      ></b-form-input>
    </td>
    <td class="text-right pr-0">

      <a v-if="enableDelete && !isDisabled" class="btn btn-icon btn-light btn-sm" @click="delete_row_clicked(row.id)">
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <!--begin::Svg Icon-->
          <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
          <!--end::Svg Icon-->
        </span>
      </a>
    </td>
  </tr>
</template>
<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
</style>
<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

export default {
  name: 'grant-budget-table-row',
  props: ['row','isDisabled','isExpense'],
  emits: ['onRowChanged', 'delete_row_clicked'],
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  data() {
    return {
      amount: 0,
      apply: true
    };
  },
  mounted() {
    this.amount = this.row.amount;
    this.name = this.row.name;
    this.apply = !this.isExpense ? this.row.apply : true;
  },
  watch: {
    apply() {
      this.onSumUpdated(null);
    },
    row(new_value) {
      this.amount = this.row.amount;
      this.name = this.row.name;
      this.apply = !this.isExpense ? this.row.apply : true;
    }
  },
  methods: {
    delete_row_clicked(id) {
      this.$emit('delete_row_clicked', id);
    },
    onSumUpdated(evt) {
      this.amount = parseInt(this.amount+'');
      if (isNaN(this.amount)) {
        this.amount = 0;
      }

      this.$emit('onRowChanged', { id: this.row.id, name: this.name, amount: this.amount, apply: this.apply });
    },
    onNameUpdated() {
      this.amount = parseInt(this.amount+'');
      if (isNaN(this.amount)) {
        this.amount = 0;
      }

      this.$emit('onRowChanged', { id: this.row.id, name: this.name, amount: this.amount, apply: this.apply });
    }
  }
};
</script>
